// Wraps InfiniteScroll to provide a more convenient interface for fetching more entries.
//
// Usage:
//   import { useMyInfiniteScroll } from '../../components/MyInfiniteScroll';
//   ... Then in your component:
//   const renderChildren= (entries) => {
//    return entries.map((entry, index) => (
//        <LogbookEntry key={index} entry={entry} onDetails={onDetails} />
//      ));
//    }
//  
//    const fetchMoreEntries = useCallback(async (pageToken) => {
//       // Fetch logbook entries for the current page. Note that nextToken could be null, so use expansion
//       // operator to optionally add it.
//       const query = { 
//         query: completedTasksByItemId, 
//         variables: { 
//           itemId: itemId, 
//           sortDirection: 'DESC', 
//           ...(pageToken && { nextToken: pageToken} )
//         }
//       };
//       let results = await client.graphql(query);  
//       return  { 
//         nextToken: results.data.completedTasksByItemId.nextToken, 
//         items: results.data.completedTasksByItemId.items 
//       };
//    }, [itemId]);
//
//    const { InfiniteScroll, entries, setEntries } = useMyInfiniteScroll(renderChildren, fetchEntries);
// 
//    return (
//      <div>
//        <InfiniteScroll />
//      </div>

import InfiniteScroll from "./infinite-scroll/index.tsx";
import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useIsMounted } from "../utilities/useMounted";
import { LoadingMessage, useStatusMessage } from "./StatusMessage";

// UI component returned by useMyInfiniteScroll.  You can use this in your component.
const MyInfiniteScroll = ({ 
    entries, 
    setEntries, 
    pageToken, 
    setPageToken, 
    hasMoreEntries, 
    setHasMoreEntries, 
    endMessage,
    fetchEntries, 
    renderChildren 
}) => {
    // See if we're still mounted after async calls.
    const isMounted = useIsMounted();
    const [StatusMessage, { showError, hideStatus }] = useStatusMessage();

    const fetchMoreEntries = useCallback(async () => {
        try {
            if (!isMounted()) {
                console.log("MyInfiniteScroll fetchMoreEntries: Not mounted.");
                return;
            }

            let { nextToken, items } = await fetchEntries(pageToken);
            console.log(`MyInfiniteScroll fetched ${items.length} more entries.`)

            if (isMounted()) {
                // Remember any next token.
                setPageToken(nextToken);
                setHasMoreEntries(!!nextToken);

                let newEntries = [];
                if (entries) {
                    newEntries = [...entries, ...items];
                } else {
                    newEntries = [...items];
                }

                setEntries(newEntries);
                hideStatus();
            }
        } catch (err) {
            showError(err);
        }
    }, [entries, fetchEntries, isMounted, pageToken, setEntries, setHasMoreEntries, setPageToken, showError, hideStatus]);

    return (
        <span>
            <InfiniteScroll
                dataLength={entries ? entries.length : 0}
                next={fetchMoreEntries}
                hasMore={hasMoreEntries}
                loader={<LoadingMessage />}
                endMessage={endMessage}
            >
                {renderChildren(entries)}
            </InfiniteScroll>
            <StatusMessage />
        </span>
    );
}

// This is the wrapped InfiniteScroll with a StatusMessage that you can use in your component.
const InfiniteScrollWithMessage = ({entries, setEntries, fetchEntries, renderChildren, pageToken, setPageToken, hasMoreEntries, setHasMoreEntries, endMessage, Message}) => {
    return (
        <span>
            <MyInfiniteScroll
                entries={entries}
                setEntries={setEntries}
                fetchEntries={fetchEntries}
                renderChildren={renderChildren}
                pageToken={pageToken}
                setPageToken={setPageToken}
                hasMoreEntries={hasMoreEntries}
                setHasMoreEntries={setHasMoreEntries}
                endMessage={endMessage && endMessage()}
            />
            {Message}
        </span>
    );
}


// Infinite scroll component that fetches more entries as needed. You supply
// the fetch function to call to get more entries in the form (nextToken || null) => { nextToken, items }.
// Returns an InfiniteScroll component that you can use in your component, the entries array and a setEntries
// function. The entries/setEntries are only needed if you want to manage the entries yourself.
// Entries is null until the first page of entries is fetched.
export const useMyInfiniteScroll = (renderChildren, fetchEntries, endMessage) => {
    // Array of entries showing.
    const [entries, setEntries] = useState(null);
    const [StatusMessage, {showError, hideStatus}] = useStatusMessage();

    // For infinite scroll, the next token to fetch more entries and whether there are more entries.
    const [pageToken, setPageToken] = useState(null);
    const [hasMoreEntries, setHasMoreEntries] = useState(true);
    
    // See if we're still mounted after async calls.
    const isMounted = useIsMounted();

    // Catch if caller has a bug where fetchEntries function changes. Should be a constant (created with useCallback).
    const alreadyFetched = useRef(false);

    // Fetch the first page of entries on page load. The InfiniteScroll component doesn't do an initial
    // fetch, so I do it.
    useEffect(() => {
        async function fetchData() {
            try {
                console.log("useMyInfiniteScroll fetchData().");

                // Show loading UI
                setEntries(null);
                setHasMoreEntries(true);

                // Go get the first page of entries.
                let { nextToken, items } = await fetchEntries(null);
                console.log(`useMyInfiniteScroll fetched ${items.length} entries.`)
                if (isMounted()) {
                    // When a query uses filters, the server can return a nextToken with no items.
                    // The InfiniteScroll component doesn't then re-fetch. So, I keep fetching here
                    // until I get items.
                    let retries = 1;
                    while (items.length === 0 && nextToken) {
                        console.log(`useMyInfiniteScroll initial fetch got no items, and nextToken. Refetching ${retries}.`);
                        // TODO: Limit # of times??
                        let result = await fetchEntries(nextToken);
                        items = result.items;
                        nextToken = result.nextToken;
                        retries = retries + 1;
                    }

                    // Remember any next token.
                    setPageToken(nextToken);
                    setHasMoreEntries(nextToken !== null);

                    let newEntries = items;
                    setEntries(newEntries);
                    hideStatus();
                } else {
                    console.log("useMyInfiniteScroll fetchData(): Not mounted.");
                }
            } catch (err) {
                // Show the error message.
                showError(err);

                // Clear the entries so the InfiniteScroll doesn't try to render them.
                setEntries(null);
                setHasMoreEntries(false);
                setPageToken(null);
            }
        }

        // Catch a bug where fetchEntries is not a constant created with useCallback, resulting in multiple calls (and infinite loop?).
        if (alreadyFetched.current) {
            console.error("useMyInfiniteScroll: Initial useEffect called twice! Probably fetchEntries is not a constant created with useCallback.");

            // TODO: While developing, sometimes the page reloads when I change code. I do want to reload, and do it from the beginning.
            // return;
        }

        if (!isMounted) {
            console.log("useMyInfiniteScroll fetchData: Not mounted.");
            return;
        }
        alreadyFetched.current = true;
        fetchData();
    }, [fetchEntries, hideStatus, isMounted, setEntries, showError]);

    const handleRenderChildren = useCallback((items) => {
        // If the first fetch hasn't completed, nothing to render.
        if (null === items) {
            return null;
        }

        return renderChildren(items);
    }, [renderChildren]);    

    const Control = useCallback(() => <InfiniteScrollWithMessage 
        entries={entries} 
        setEntries={setEntries} 
        fetchEntries={fetchEntries} 
        renderChildren={handleRenderChildren} 
        pageToken={pageToken} 
        setPageToken={setPageToken} 
        hasMoreEntries={hasMoreEntries} 
        setHasMoreEntries={setHasMoreEntries} 
        endMessage={endMessage} 
        Message={<StatusMessage/>} 
    />, [endMessage, entries, fetchEntries, handleRenderChildren, hasMoreEntries, pageToken]);
    
    return useMemo(() => { return { 
        Control,
        entries, 
        setEntries 
    }}, [Control, entries]);
}
