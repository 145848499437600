import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Home, Support, Profile, ChangePassword, Tasks, 
         Task, Items, Item, ItemImage, EmailItem, 
         Logbook, LogbookEntry, LogbookPickTask, LogbookEntryFromTask,
        Test } from "./pages";
import { Navigation, PageNotFound } from "./components";
import { Authenticator } from '@aws-amplify/ui-react';
import { Hub } from '@aws-amplify/core';
import { getCurrentUser, signOut, fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { ErrorBoundary } from "react-error-boundary";
import { useStatusMessage } from './components/StatusMessage';
import * as Urls from './fleet-shared/Urls.mjs';

import '@aws-amplify/ui-react/styles.css';

import './App.css';

// For debugging purposes, spoofs the database/auth.
export const testMode = false;

// Shown when there's an uncaught exception
const CaughtError = () => {
  // const location = useLocation();

  // const initialUrl = useState(location.pathname);

  // TODO: Get this working.
  // For clearing out the ErrorBoundary on browser navigation (browser back).
  // useEffect(() => {
  //   // TODO: Does this get triggered on first render?
  //   if ((location.pathname !== initialUrl) && location.state && location.state.hasError) {
  //     location.state.hasError = false;
  //   }
  // }, [location, initialUrl])

  return (
    <div className="container text-center text-danger pt-8">
      <p>Something went wrong on our end.</p>
      <p>If the problem persists, please <a href={Urls.urlForSupport()}>contact us</a>.</p>
      <p><a href='/'>Home</a></p>
    </div>
  );
}

// Wraps children in an authenticator
const RequireAuth = () => {
  // In test mode, no auth -- just pretend user is logged in.
  if (testMode) {
    return <Outlet/>
  }

  // Otherwise use the Authenticator
  return (
    <Authenticator signUpAttributes={["email"]}>
      {({ signOut, user }) => (
        <Outlet/>
      )}
    </Authenticator>
  );
}


function App() {
  // I want to know the signed in user. This is async in React, so I get it at app startup and update if changes
  // See: https://github.com/aws-amplify/amplify-js/issues/3640
  // I add a few additional attributes to the user from Auth -- email, email_verified, and identityId.
  let [user, setUser] = useState(null);
  let [isInitializing, setIsInitializing] = useState(true);
  let [StatusMessage, {/*showLoading, showText, */ showError, hideStatus}] = useStatusMessage();

  // For auth:
  useEffect(() => {
    let updateUser = async authState => {
      setIsInitializing(true);
      // showLoading('Loading user...');

      // I was doing Promise.all here to do all these in parallel. I periodically would get
      // a TooManyRequestsException: Rate exceeded.  I think it was because I was doing the
      // requests in parallel.  I'm not sure if that's the cause, but I'm going to do them in
      // series now.
      try {
        let user = await getCurrentUser();
        let attributes = await fetchUserAttributes();
        let session = await fetchAuthSession();
        user.email = attributes.email;
        user.email_verified = attributes.email_verified;
        user.identityId = session.identityId;
        setUser(user);
        hideStatus();
      } catch(err) {
        console.error('Error getting user: ', err);
        // Show user the error (but not on home page)...
        if (window.location.pathname !== '/') {
          showError(err);

          // TODO: This got in to an infinite loop. But I also got
          // in a state where the user was not signed in, but the
          // Authenticator UI would not show. getUser would return
          // a user, but fetchUserAttributes would throw and then
          // signout would throw, and then we'd loop forever (cuz
          // the signin state changed before the throw???, so Hub
          // called back??).
          
          // try {
          //   await signOut();
          // }
          // catch (err) {
          //   console.error('Error signing out: ', err);
          // }
        }
        setUser(null);
      }
      finally {
        // Done initializing.
        setIsInitializing(false);
      }
    }

    Hub.listen('auth', updateUser) // listen for login/signup events
    updateUser() // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser) // cleanup
  }, [hideStatus, showError]);



  async function signOutUser() {
    try {
      await signOut();
    } catch (error) {
      // Show user the error...
      console.error('error signing out: ', error);
      showError(error);
    }
  }


  // Home - Describes product
  // support a product support page
  // me/item list of items the user is tracking (like a list of boats)
  // me/item/:id - Lists one item (a top level item, like a vessel or home)
  // me/item/:id/tasks(/:type) - A list of tasks belonging to the item with the given ID. Type is optional, omitted = all.
  // me/profile - Logged in users profile.
  // Page not found for everything else.
  // TODO: I have PageNotFound in several places, how to do 1?
  return (
    <ErrorBoundary fallback={<CaughtError/>}>
      <main>
        <BrowserRouter>
          <Navigation user={user} isInitializing={isInitializing} signOut={signOutUser} />
          <StatusMessage />

          <Routes>
            <Route path='/' element={<Home user={user}/>}/>
            <Route path='support' element={<Support/>}/>
            <Route path='404.html' element={<PageNotFound/>}/>
            <Route path='test' element={<Test/>} />
            <Route path='me/*' element={<RequireAuth/>}>
              <Route path='profile' element={<Profile user={user}/>}/>
              <Route path='changepass' element={<ChangePassword user={user}/>}/>
              <Route path='item/*' element={
                <Routes>
                  <Route path='/' element={<Items user={user}/>}/>
                  <Route path=':itemId/*' element={
                    <Routes>
                      <Route path='/' element={<Item user={user}/>}/>
                      <Route path='email' element={<EmailItem user={user}/>}/>
                      <Route path='task/:taskId' element={<Task user={user}/>}/>
                      <Route path='tasks' element={<Tasks user={user}/>}/>
                      <Route path='tasks/:listType' element={<Tasks user={user}/>}/>
                      <Route path='logbook/*' element={
                        <Routes>
                          <Route path='/' element={<Logbook user={user}/>}/>
                          <Route path='picktask' element={<LogbookPickTask user={user}/>}/>
                          <Route path='fromtask/:taskId' element={<LogbookEntryFromTask user={user}/>}/>
                          <Route path=':entryId' element={<LogbookEntry user={user}/>}/>
                          <Route path="*" element={<PageNotFound/>}/>
                        </Routes>
                      }/>
                      <Route path='image' element={ <ItemImage user={user} /> } />
                      <Route path='*' element={<PageNotFound/>}/>
                    </Routes> 
                  }/>
                </Routes>
              }/>
              <Route path="*" element={<PageNotFound/>}/>
            </Route>
            <Route path="*" element={<PageNotFound/>}/>
          </Routes>      
        </BrowserRouter>
      </main>
    </ErrorBoundary>
  );
}

export default App;
