// Displays a task -- ID comes from a parameter in the URL or data can be passed in state from prior
// page.
//
// If in URL, loads from database and puts in location.state.
// In state I have:
//    state.task
//    state.item
//    state.back : optional [Extras] for ItemBreadcrumbs

import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useStatusMessage, Attachments, Confirm, DataCard, StatusBadge } from '../../components';
import { ItemBreadcrumbs } from '../item/ItemBreadcrumbs';
import { taskFields } from '../../fleet-shared/TaskFields.mjs';
import { deleteTask, copyTaskToCompletedTasks } from '../../utilities/Database.mjs';
import { displayDate } from '../../fleet-shared/Common.mjs';
import { addBucketNameToTasks } from '../../fleet-shared/TaskBuckets.mjs';
import { urlForItem, urlForLogbookEntryFromTask, urlForTaskStorage, urlForTasks } from '../../fleet-shared/Urls.mjs';
import { useEditTaskPopup } from './useEditTaskPopup';
import { CardHeader } from '../../components/CardHeader';

import { generateClient } from 'aws-amplify/api';
export const client = generateClient();

const getTaskPogoTask = /* GraphQL */ `
    query GetTaskPogoTask(
      $itemId: ID!
      $taskId: ID!) 
    {
      getTaskPogoItem(id: $itemId) {
        id
        name
        itemCounter {
          items {
            id
            name
            counter
          }
          nextToken
        }
      }

      getTaskPogoTask(id: $taskId) {
        id
        name
        assignedEmail
        priority
        rank
        cost
        itemId
        item {
          id
          name
          parentId
        }
        description
        notes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        itemCounterId
        itemCounter {
          name
          counter
        }
      }
    }
  `;

const Task = () => {
  // For URL management, state.
  const navigate = useNavigate();

  // Passed on URL:
  const { itemId, taskId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // State for data
  const [item, setItem] = useState(null);
  const [task, setTask] = useState(null);

  // State for UI
  // const [back, setBack] = useState(null);

  // Confirm delete
  const [showConfirm, setShowConfirm] = useState(false);

  // Status message
  const [StatusMessage, {showText, showError, showLoading, hideStatus}] = useStatusMessage();

  useEffect(() => {
    // Loads the item/task on first load.
    async function load() {
      try {
        showLoading();
        var taskData = await client.graphql({ 
          query: getTaskPogoTask, 
          variables: {
            itemId: itemId, 
            taskId: taskId 
          } 
        });

        // Go get the data from the database.
        const item = taskData.data.getTaskPogoItem;
        const task = taskData.data.getTaskPogoTask;

        // I use -1 in database for no priority.
        if (task.priority === -1) {
          task.priority = null;
        }
        addBucketNameToTasks([task]);

        // Set the state.
        setItem(item);
        setTask(task);  

        // Hide the loading message.
        hideStatus();
      } catch (err) {
        showError(err);
      }
    }
    load();
  }, [taskId, itemId, hideStatus, showError, showLoading]);

  // Task editing popup UI
  const { 
    showEditTaskPopup, 
    TaskEditor 
  } = useEditTaskPopup({ 
    item: item, 
    taskEdited: (editedTask) => {
      setTask(editedTask);
    }, 
    taskDeleted: () => {
      // Go back to parent, as this guy is gone...
      let url = urlForItem(itemId);
      navigate(url, { replace: true });
    }
  }, searchParams.get("edit") === '1');


  // Confirm delete, then delete item.
  function deleteTapped() {
    setShowConfirm(true);
  }

  // User confirmed they want to delete this item.
  async function onConfirmDelete() {
    try {
      showText('Deleting...');
      setShowConfirm(false);

      // Delete the item and all its children:
      await deleteTask(task.id);

      // Status is good:
      hideStatus();

      // TODO: This should go back to all Tasks or whatever if came from there.
      
      // Go to parent, as this guy is gone... Either sent in state in back, or
      // go to Item.
      let url = urlForItem(itemId);

      // TODO: Could go back to the Tasks page, ya?
      // if (back) {
      //   url = back.url;
      // }
      navigate(url, { replace: true });
    } catch (err) {
      setShowConfirm(false);
      showError(err);
    }
  }


  function editTapped() {
    // Could be called cuz the edit flag was on the URL. Clear it, so we don't keep editing.
    setSearchParams({edit: '0'});
    showEditTaskPopup(task);
  }

  async function addToLogbook() {
    showText('Adding...');

    // Add entry to database.
    let newTask = await copyTaskToCompletedTasks(task, item);

    // TODO: Edit entry page, not from task page... With flag to show update task...
    // Navigate to the page to edit the entry.
    navigate(urlForLogbookEntryFromTask(itemId, newTask.id));
  }


  const Header = ({name}) => {
    return (
      <CardHeader 
        text={name} 
        belowText=<StatusBadge status={task.bucketName}/> 
        deleteTapped={deleteTapped} 
        editTapped={editTapped}
        addTapped={addToLogbook}
        addText='Add to Logbook'
      />
    );
  }

  // Text to display for a task's field. Translates things like itemCounterId to name.
  const TaskText = ({field}) => {
    if (field.key === 'itemCounterId') {
      return (
        <div>
          {task.itemCounter ? task.itemCounter.name : ''}
        </div>
      );
    } else if (field.key === 'cost') {
      if (task[field.key] === -1) {
        return (<div></div>);
      } else {
        return (
          <div>
            {task[field.key]}
          </div>
        );
      }
    } else {
      return (
        <div>
          {(field.type === 'date') ? displayDate(task[field.key]) : task[field.key]}
        </div>
      );
    }
  }

  // One field in a task.
  const TaskItem = ({field}) => {
    return (
      <td align="left" className="col-6 entered-data">
        <TaskText field={field}/>
      </td>
    );
  }

  // Table of tasks.
  const TaskTable = () => {
    return (
      <div className="table-responsive">
        <table className="table table-hover text-nowrap table-striped">
          <tbody>
              {
              taskFields.map((field) => (
                <tr key={field.name} >
                  <td align="right" className="col-6">{field.name}</td>
                  <TaskItem field={field}/>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }



  return (
    <div>
      <ItemBreadcrumbs item={item} extras={[{name: 'Tasks', url: urlForTasks(itemId)}]} />

      <StatusMessage />

      { 
        task && (
          <div>
            <DataCard
              header={Header(task)} 
              body={TaskTable()}
            />

            <Attachments bucket={urlForTaskStorage(itemId, task.id)} />

          </div>
        )
      }

      { TaskEditor }

      <Confirm
        show={showConfirm}
        onConfirm={onConfirmDelete}
        onHide = {() => setShowConfirm(false)}
        body='Are you sure?'
        confirmText='Confirm Delete'
        title={`Delete`}>
      </Confirm>

    </div>
  )
}

export default Task;

