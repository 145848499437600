// Displays an item, like a vessel, engine, generator, etc -- ID comes from a parameter in the URL.

import { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { itemFields } from '../../fleet-shared/ItemFields.mjs';
import { 
  useStatusMessage, 
  LogbookSummary, 
  Attachments,  
  EquipmentInfoUI, 
  EquipmentList, 
  ModalEditForm, 
  HourMeter, 
  UpcomingTasks, 
  Confirm } from '../../components';
import * as TaskBuckets from '../../fleet-shared/TaskBuckets.mjs';
import { deleteCounter, updateCounter, NewItemName, createItem, deleteItem, createTask, saveEditedItem } from '../../utilities/Database.mjs';
import { urlForItem, urlForAllItems, urlForItemStorage, urlForTasks, urlForLogbook, urlForTaskId, urlForEmailItem } from '../../fleet-shared/Urls.mjs';
import { useEditTaskPopup } from '../task/useEditTaskPopup';
import { ItemBreadcrumbs } from './ItemBreadcrumbs';
import { client } from '../../utilities/Database.mjs';



const deepGetItem = /* GraphQL */ `
  query GetTaskPogoItem($id: ID!) {
    getTaskPogoItem(id: $id) {
      id
      name
      description
      manufacturer
      serialNumber
      partNumber
      location
      notes
      dateInstalled
      counterInstalled
      parentId
      createdAt
      updatedAt
      owner
      parentItem {
        id
        name
      }
      childItems {
        items {
          id
          name
        }
        nextToken
      }
      tasks {
        items {
          id
          name
          itemId
          description
          notes
          dueCounter
          dueDate
          counterInterval
          daysInterval
          itemCounterId
        }
        nextToken
      }
      completedTasks(limit: 5, sortDirection: DESC) {
        items {
          id
          name
          itemId
          itemCounterId
          taskId
          description
          taskNotes
          dueCounter
          dueDate
          counterInterval
          daysInterval
          notes
          counter
          date
        }
      }
      itemCounter {
        items {
          id
          name
          counter
          itemId
          createdAt
          updatedAt
        }
        nextToken
      }
      emails {
        items {
          id
          emailType
          name
          email
          sendHourGMT
          itemId
          sendEveryDays
          lastSentDate
          nextSendDate
        }
      }
    }
  }
`;

const ItemUI = ( {item, identityId, showText, showError, hideStatus, setItem} ) => {

  // Various status badges:
  const [counterItems, setCounterItems] = useState(null);

  // The task buckets for this item's tasks (see utitlies/TaskBuckets.js)
  const [upcomingTaskBuckets, setUpcomingTaskBuckets] = useState(null);

  // Edit Item UI.  When to show it, and what to show.
  const [showEditItem, setShowEditItem] = useState(false);
  const [editItem, setEditItem] = useState(null);

  // Confirm delete
  const [showConfirm, setShowConfirm] = useState(false);

  const navigate = useNavigate();

  const taskEdited = useCallback(() => {
    // Update the coming soon tasks UI
    const buckets = TaskBuckets.taskBuckets(item);
    setUpcomingTaskBuckets(buckets);    
  }, [item]);

  const taskDeleted = useCallback(() => {
    // Update the coming soon tasks UI
    const buckets = TaskBuckets.taskBuckets(item);
    setUpcomingTaskBuckets(buckets);
  }, [item]);

  // Popup for editing a task. Returns function to show the popup and the popup itself to be put in the UI.
  const { showEditTaskPopup, TaskEditor } = useEditTaskPopup({ item, taskEdited, taskDeleted });


  // Whenever the item changes, I update the Counter/Upcoming Tasks
  useEffect( () => {
    console.info('updateUI');

    // Update the counter UI.
    if ((null != item) && (null != item.itemCounter)) {
      setCounterItems(item.itemCounter.items);
    } else {
      setCounterItems(null);
    }

    // Update the coming soon tasks UI
    const buckets = TaskBuckets.taskBuckets(item);
    setUpcomingTaskBuckets(buckets);

    return () => {
      console.log("updateUI unmounted");
    }
  }, [item]);


  // State to show the all tasks pane.
  const [showTaskBucket, setShowTaskBucket] = useState(false);
  const [taskBucket, setTaskBucket] = useState(null);

  // When the item changes, update the UI. 
  useEffect( () => {
    // If showTaskBucket changes to true, goto the tasks page.
    if (showTaskBucket) {
      navigate(urlForTasks(item.id, taskBucket.tasksUrl));
    }
  }, [item, navigate, showTaskBucket, taskBucket]);


  // Bring up the edit UI for this item.
  const editTapped = useCallback(() => {
    setEditItem(item);
    setShowEditItem(true);
  }, [item]);



  // Delete the given counterId
  const deleteCounterItem = useCallback(async(id) => {
    // This can throw, caller catches and shows error...
    await deleteCounter(id, item.itemCounter.items);
  }, [item]);



  // Passed to EditHours.  When the Save button is pressed, this is called.
  // throws on errors.
  const saveCounter = useCallback(async (counter) => {
    // This can throw, caller catches and shows error...
    await updateCounter(counter, item.itemCounter.items);
  }, [item]);


  // Confirm delete, then delete item.
  const deleteTapped = useCallback(() => {
    setShowConfirm(true);
  }, [setShowConfirm]);


  // User confirmed they want to delete this item.
  const onConfirmDelete = useCallback( async () => {
    showText("Deleting...")

    try {
      setShowConfirm(false);
      // Delete the item and all its children:
      await deleteItem(item);

      // Status is good:
      hideStatus();

      // Go to parent, as this guy is gone...
      if (item.parentId != null) {
        navigate(urlForItem(item.parentId), { replace: true });
      } else {
        navigate(urlForAllItems(), {replace: true });;
      }
    } catch (err) {
      setShowConfirm(false);
      showError(err);
    }
  }, [showText, setShowConfirm, hideStatus, showError, item, navigate]);



  // Passed to EditItem.  When the Save button is pressed, this is called.
  // throws on errors.
  const saveEditedItemForm = useCallback(async (newItem) => {
    // This can throw, caller catches and shows error...
    let savedItem = await saveEditedItem(newItem, item);

    // Editing this item?  Update the UI.
    if (item.id === savedItem.id) {
      Object.assign(item, newItem);
    } else {
      // TODO: Can't equipment have equipment. i.e. should this be recursive?
      // or no -- cuz can only edit a child in current UI, not a child of a child.

      // No, editing some equipment, update UI for that...
      const cItems = item.childItems.items.length;
      for (var i=0; i < cItems; i++) {
        if (item.childItems.items[i].id === newItem.id) {
          item.childItems.items[i] = savedItem;
          break;
        }
      }
    }

    setShowEditItem(false);
  }, [item]);

  
  // Adds new equpment to the item.  Throws on error.  Returns a link to the new equipment.
  const addEquipment = useCallback(async () => {
    // Add item to the database
    let newItem = {
      name: NewItemName,
      parentId: item.id
    };

    // This can throw, caller catches and shows error...
    const createdItem = await createItem(newItem);
    item.childItems.items = [...item.childItems.items, createdItem];

    setEditItem(createdItem);
    setShowEditItem(true);

    return createdItem;
  }, [setEditItem, setShowEditItem, item]);


  // Adds a new task to this item. Throws on error.
  const addTask = useCallback(async () => {
      var task = await createTask(item);

      // Update the model. I copy the item and set it again to force the re-render
      let newItem = {...item};
      newItem.tasks.items.push(task);
      setItem(newItem);

      // Show the edit task UI
      showEditTaskPopup(task);
  }, [item, setItem, showEditTaskPopup]);


  // When click on a task, go to the task detail page.
  const onClickTask = useCallback((task) => {
    navigate(urlForTaskId(task.itemId, task.id));
  }, [navigate]);


  const onAllLogbook = useCallback(() => {
    navigate(urlForLogbook(item.id));
  }, [navigate, item]);

  const onLogbookAdded = useCallback((task) => {
    // TODO: Why don't I need to copy the item here?

    // Add the new task
    var tasks = [...item.completedTasks.items, task].sort((a,b) => {
      if (a.date < b.date) {
        return -1;
      } else if (a.date > b.date) {
        return 1;
      }
      return 0;
    });

    // Sorted by date.

    item.completedTasks.items = tasks;
  }, [item]);

  const onLogbookSaved = useCallback((task) => {
    // TODO: Why don't I need to copy the item here?

    var tasks = [...item.completedTasks.items];
    tasks = tasks.map((t) => {
      if (t.id === task.id) {
        return task;
      }
      return t;
    });
    item.completedTasks.items = tasks;
  }, [item]);

  const onLogbookDelete = useCallback((entry) => {
    // Update the model. I copy the item and set it again to force the re-render
    let newItem = {...item};
    var tasks = newItem.completedTasks.items.filter(i => i.id !== entry.id);
    newItem.completedTasks.items = tasks;

    setItem(newItem);
  }, [item, setItem])

  const emailTapped = useCallback(() => {
      navigate(urlForEmailItem(item.id));
  }, [navigate, item]);


  return (
    (item != null) &&
    <div className="pb-3">
      <EquipmentInfoUI 
        identityId={identityId}
        item={item} 
        deleteTapped={deleteTapped} 
        editTapped={editTapped}
        emailTapped={emailTapped} />

      <HourMeter 
        itemId={item.id} 
        counterItems={counterItems} 
        save={saveCounter} 
        delete={deleteCounterItem} />

      <UpcomingTasks 
        buckets={upcomingTaskBuckets}
        onClickTask={onClickTask}
        setTaskBucket={setTaskBucket}
        setShowTaskBucket={setShowTaskBucket}
        addTask={addTask}
      />

      <LogbookSummary
        item={item}
        onAll={onAllLogbook}
        onAdded={onLogbookAdded}
        onSaved={onLogbookSaved}
        onDelete={onLogbookDelete}
      />

      <EquipmentList 
        equipment={ item.childItems.items || [] } 
        heading={ (item.parentId == null) ? "Equipment" : "Parts"} 
        addEquipment={ addEquipment }
      />

      <ModalEditForm
        show={showEditItem}
        fields={itemFields}
        onHide={() => setShowEditItem(false)}
        item={editItem}
        save={saveEditedItemForm}
      />
      
      {TaskEditor}

      <Confirm
        show={showConfirm}
        onConfirm={onConfirmDelete}
        onHide = {() => setShowConfirm(false)}
        body='Are you sure?'
        confirmText='Confirm Delete'
        title={`Delete ${item.name}`}>
      </Confirm>
    </div>
  )
}



const Item = ({user}) => {
  // Loading/error UI
  const [StatusMessage, {showLoading, showText, showError, hideStatus}] = useStatusMessage();

  // Passed on URL:
  const { itemId } = useParams();
  const [item, setItem] = useState(null);

  // When the itemId changes, retrieve the item.
  useEffect(() => {
    async function retrieveItem(id) {
      try {
        showLoading();
        var itemData = await client.graphql({ query: deepGetItem, variables: { id: id } });

        // TODO: Handle nextToken in tasks, childItems, itemCounter, etc.
        setItem(itemData.data.getTaskPogoItem);
        hideStatus();
      } catch (err) {
        showError(err);
      }
    }
    retrieveItem(itemId);
  }, [hideStatus, itemId, showError, showLoading]);

  return (
    <div>
      <ItemBreadcrumbs item={item} />

      <StatusMessage/>
      <ItemUI identityId={user ? user.identityId : null} item={item} showText={showText} showError={showError} hideStatus={hideStatus} setItem={setItem}/>
      <Attachments bucket={urlForItemStorage(itemId)} hideLoading={(item === null)} />
    </div>
  )
}

export default Item;

