// Utilities for URL's and ItemBreadcrumbs
const siteUrl = 'https://www.taskpogo.com';

// URL for unsubscribes. TODO: Should the key/itemId be posted?
export function urlForUnsubscribe(itemId, unsubscribeKey) {
    return `${siteUrl}/unsubscribe?itemId=${itemId}&unsubscribeKey=${unsubscribeKey}`;
}

export function urlForSupport() {
    return '/support';
}

export function isUrlForSupport(url) {
    return (url.toLowerCase() === "/support");
}

export function urlForProfile() {
    return '/me/profile';
}

export function urlForChangePassword() {
    return '/me/changepass';
}

export function urlForAllItems() {
    return '/me/item';
}

export function isUrlForAllItems(url) {
    return (url.toLowerCase() === '/me/item');
}

export function isUrlUnderAllItems(url) {
    return url.startsWith('/me/item');
}

export function urlForItem(itemId) {
    return `/me/item/${itemId}`;
}

export function fullUrlForItem(itemId) {
    return `${siteUrl}${urlForItem(itemId)}`;
}

export function urlForItemImage(itemId) {
    return `/me/item/${itemId}/image`;    
}

export function urlForEmailItem(itemId) {
    return `/me/item/${itemId}/email`;
}

export function fullUrlForEmailItem(itemId) {
    return `${siteUrl}${urlForEmailItem(itemId)}`
}

export function urlForTaskId(itemId, taskId) {
    return `/me/item/${itemId}/task/${taskId}`
}

export function urlForEditTaskId(itemId, taskId) {
    return `/me/item/${itemId}/task/${taskId}?edit=1`
}

export const bucketNameAll = 'all';
export const bucketNameOverdue = 'overdue';
export const bucketNameSoon = 'soon';

export function urlForTasks(itemId, bucketName) {
    return `/me/item/${itemId}/tasks/${bucketName}`
}

export function urlForLogbook(itemId) {
    return `/me/item/${itemId}/logbook`;
}

export function urlForLogbookEntry(itemId, entryId) {
    return `/me/item/${itemId}/logbook/${entryId}`;
}

export function urlForLogbookPickTask(itemId) {
    return `/me/item/${itemId}/logbook/picktask`;    
}

export function urlForLogbookEntryFromTask(itemId, taskId) {
    return `/me/item/${itemId}/logbook/fromtask/${taskId}`;
}


// S3 path for storing items for the given id.
export function urlForItemStorage(itemId) {
    return `${itemId}/`;
}


// ******* Thumbnails *************************
// All 'thumbnails' have this prefix:
export const thumbnailPrefix = '~~thumbnail-';
export const thumbnailSmallSuffix = 'small.jpg';
export const thumbnailMediumSuffix = 'medium.jpg';

// The "small" thumbnail has a max width/height of:
export const ThumbnailSmallSize = 64;
export const ThumbnailMediumSize = 256;

export function itemThumbnailPrefix(bucket) {
    return `${bucket}${thumbnailPrefix}`;
} 

// This is the URL used when originally uploading the thumbnail. A Lambda, TaskPogoImageProcessor
// then resizes to small/medium and renames the uploaded file to urlForItemThumbnailOriginal...
export function urlForItemThumbnailUpload(itemId, filename) {
  return `${itemThumbnailPrefix(urlForItemStorage(itemId))}${filename}`;
}

// This is the URL for the "small" thumbnail. The server resizes the original to this size:
export function urlForItemThumbnailSmall(itemId) {
  return `${itemThumbnailPrefix(urlForItemStorage(itemId))}${thumbnailSmallSuffix}`;
}

// This is the URL for the "medium" thumbnail. The server resizes the original to this size:
export function urlForItemThumbnailMedium(itemId) {
  return `${itemThumbnailPrefix(urlForItemStorage(itemId))}${thumbnailMediumSuffix}`;
}

// The key portion (no bucket or user/itemId path) for S3 for the small thumbnail. Used by server to create the resized original.
export function keyForThumbnailSmall() {
    return `${thumbnailPrefix}small.jpg`;
}

// The key portion (no bucket) for S3 for the medium thumbnail. Used by server to create the resized original.
export function keyForThumbnailMedium() {
    return `${thumbnailPrefix}medium.jpg`;
}


// ****************************************************
// S3 path for storing items for the given item/taskId.
export function urlForTaskStorage(itemId, taskId) {
    return `${itemId}/task/${taskId}/`;
}

export function urlForLogbookEntryStorage(itemId, entryId) {
    return `${itemId}/logbook/${entryId}/`;
}
