// A component that renders a list of tasks.

import { useCallback, useState, useRef } from 'react';
import { addBucketNameToTasks, graphQLSoon, soonHours, nameOverdue, nameSoon } from '../../fleet-shared/TaskBuckets.mjs';
import { bucketNameAll, bucketNameSoon, bucketNameOverdue } from '../../fleet-shared/Urls.mjs';
import { client } from '../../utilities/Database.mjs';
import { SortOverdue, SortAssigned, SortPriority } from './Tasks.jsx';

// These are the fields I want back for a task.
const taskFields = `
  id
  name
  assignedEmail
  priority
  rank
  cost
  itemId
  description
  notes
  dueCounter
  dueDate
  counterInterval
  daysInterval
  itemCounter {
    counter
    name
  }
`;

// This is the query for the parent item, which includes the counters and their tasks.
const getTaskPogoItem = /* GraphQL */ `
  getTaskPogoItem(id: $itemId) {
    id
    name
    parentId
    itemCounter {
      items {
        id
        name
        counter
        tasks {
          items {
            ${taskFields}
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

const tasksByItemIdAndDueDate = /* GraphQL */ `
  tasksByItemIdAndDueDate(
    itemId: $itemId
    sortDirection: $sortDirection
    filter: $filter
  ) {
    items {
      ${taskFields}
    }
    nextToken
  }
`;


// This query is for getting more counters.
export const itemCounterByItemIdQuery = /* GraphQL */ `
  query ItemCounterByItemId(
    $itemId: ID!
    $nextToken: String
  ) {
    itemCounterByItemId(
      itemId: $itemId
      nextToken: $nextToken
    ) {
      items {
        id
        name
        counter
      }
      nextToken
    }
  }
`;


const LoadingOverdueByDate = 0;
const LoadingOverdueByCounters = 1;
const LoadingSoonByDate = 2;
const LoadingSoonByCounters = 3;
const LoadingNullDates = 4;
const LoadingAllCounters = 5;

// This is a hook that fetches tasks that are overdue. It also fetches the item.
// I pass in setItem so that the item can be set in the parent component (and we
// don't re-call the query to get the tasks cuz the item changed).
export const useTaskQuery = (itemId, listType, sortType, setItem) => {
  const itemRef = useRef(null);
  const oldSort = useRef(null);

  // All items with a dueDate < now are overdue.
  const [now] = useState(new Date());
  const [soon] = useState(graphQLSoon());

  const loadingState = useRef(LoadingOverdueByDate);
  const scratchpad = useRef(null);

  // This is the generic fetch function that fetches tasks by date, assigned, or priority.
  const fetchFunction = useCallback(async (query, variables, resultBucket, hasItemQuery) => {
    const itemsData = await client.graphql({ query: query, variables: variables });

    // Remember the item.
    if (hasItemQuery && itemsData.data.getTaskPogoItem) {
      itemRef.current = itemsData.data.getTaskPogoItem;
      setItem(itemRef.current);
    }

    // First batch of overdue tasks based on date:
    var result = itemsData.data[resultBucket];
    var items = result.items;
    var nextToken = result.nextToken;
    addBucketNameToTasks(items);

    return [ items, nextToken, itemsData.data.getTaskPogoItem ];  
  }, [setItem]);  


  // This takes the item and if it has more counters to fetch, fetches them.
  const fetchAllItemCounters = useCallback(async (item) => {
    let counters = item.itemCounter.items;
    let nextToken = item.itemCounter.nextToken;

    while (nextToken) {
      console.log(`Fetching more counters for itemId ${itemId}: ${nextToken}`);
      const itemsData = await client.graphql({ query: itemCounterByItemIdQuery, variables: { itemId: itemId, nextToken: nextToken }});
      const newCounters = itemsData.data.itemCounterByItemId.items;
      counters = counters.concat(newCounters);
      nextToken = itemsData.data.itemCounterByItemId.nextToken;
    }

    item.itemCounter.items = counters;
  }, [itemId]);


  // This is the fetch for more tasks by date.
  const fetchByDate = useCallback(async (token, dueDate) => {
    var query = `
      query TasksByItemIdAndDueDate(
        $itemId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelTaskPogoTaskFilterInput
      ) {
        ${itemRef.current ? '' : getTaskPogoItem}
        ${tasksByItemIdAndDueDate}
      }
    `;
    var variables = { 
      itemId: itemId,
      sortDirection: 'ASC'
    };

    // If passed in dueDate, use it. Otherwise, look for null dueDates.
    if (dueDate) {
      variables.filter = dueDate;
    } else {
      variables.filter = { 
        or: [ 
          {
            dueDate: {eq: null, attributeExists: true}
          },
          {
            dueDate: {attributeExists: false}
          }
        ]
      }
    }

    if (token) {
      variables.nextToken = token;
    }

    return fetchFunction(query, variables, "tasksByItemIdAndDueDate", itemRef.current == null);
  }, [fetchFunction, itemId]);


  // This is the fetch searching for overdue items by counter.
  const fetchUsingCounters = useCallback(async (token, includeFunction) => {
    let items = [];
    let nextToken = null;

    // TODO: Not true?????????
    // If there's a nextToken, we've been called to get more tasks. 
    if (token) {
      const itemsData = await client.graphql({ query: itemCounterByItemIdQuery, variables: { itemId: itemId, nextToken: token }});

      if (!itemRef.current) {
        console.error("No itemRef.current in fetchUsingCounters");
        return;
      }

      const newCounters = itemsData.data.itemCounterByItemId.items;

      // Add the new counters to the itemRef.current.itemCounter.items.
      for (let newCounter of newCounters) {
        itemRef.current.itemCounter.items.push(newCounter);
      }
    }


    // Start looking through the counters and find tasks that are overdue.
    counterLoop:
    for (let itemCounter of itemRef.current.itemCounter.items) {
      // If we've already processed this counter, skip it.
      if (itemCounter.processed) { continue }

      // Inspect the tasks for this counter looking for overdue tasks.
      let tasks = itemCounter.tasks.items;
      for (let task of tasks) {
        // If we've already processed this task, skip it.
        if (task.processed) { continue }

        if (includeFunction(itemCounter.counter, task.dueCounter)) {
          items.push(task);
          task.processed = true;
        } else {
          continue counterLoop;
        }
      }
      // All tasks processed, so mark this counter as processed.
      itemCounter.processed = true;


      // If there's a nextToken, return it, we'll get called back with it for more.
      // TODO: ??
      nextToken = itemCounter.tasks.nextToken;
      if (nextToken) { 
        // Don't want to return this nextToken again -- we'll use it in the next call and not again.
        itemCounter.tasks.nextToken = null;
        break;
      }
    }

    addBucketNameToTasks(items);
    return [items, nextToken];
  }, [itemId]);


  // Test if the counter is overdue.
  function counterIsOverdue(counter, taskCounter) {
    return counter >= taskCounter;
  }

  // Tests if a counter is soon.
  function counterIsSoon(counter, taskCounter) {
    return counter < taskCounter && counter <= taskCounter + soonHours;
  }

  // Tests if a counter is in the future.
  function counterIsFuture(counter, taskCounter) {
    return counter < taskCounter - soonHours;
  }

  // This calls the passed in fetch function and adds the items to the items array, if the
  // array doesn't already contain the item.
  async function doTheFetch(theFetch, arg1, arg2, items) {
    var [i, n] = await theFetch(arg1, arg2);

    // Add the items to the list, if they're not already there.
    for (let item of i) {
      // TODO: O(n)
      if (!items.find((element) => element.id === item.id)) {
        items.push(item);
      }
    }
    return [items, n];
  }


  // This is the fetch function when sorting by overdue. 
  // TODO: Make not a state machine. Do everything in parallel.
  // It's a state machine that fetches tasks by date and then by counter.
  const fetchSortOverdue = useCallback(async (token) => {
    console.log(`*** FetchItems ${token ? '' : 'No token'} ***`);
    var items = [];
    let nextToken = null;

    // If no token, we might be starting over. Start the state machine over.
    // If the sort type changed, go back to beginning of state machine.
    if ((null === token) || oldSort.current !== sortType) {
      loadingState.current = LoadingOverdueByDate;
      oldSort.current = sortType;
      itemRef.current = null;
    }

    // Loops until gets a nextToken or done.
    for (;;) {
      switch (loadingState.current) {
        case LoadingOverdueByDate:
          // In this state, I look for tasks that are overdue by date.
          console.log(`LoadingByDate: ${token ? 'with token' : 'null'}`);
          var [i, n] = await doTheFetch(fetchByDate, token, { dueDate: {lt: now.toISOString(), attributeExists: true} }, items);
          items = i;
          nextToken = n;

          // No nextToken, move on to the next state.
          if (!nextToken) {
            loadingState.current = LoadingOverdueByCounters;
          }
          break;

        case LoadingOverdueByCounters:
          // In this state, I look through the item's counters and find tasks that are overdue.
          console.log(`LoadingOverdueByCounters: ${token ? 'with token' : 'null'}`);

          // TODO: What if I already added the task in LoadingFirst or LoadingByDate?
          [i, n] = await doTheFetch(fetchUsingCounters, token, counterIsOverdue, items);
          items = i;
          nextToken = n;

          // If there is no nextToken, move on to the next state.
          if (!nextToken) {
            loadingState.current = LoadingSoonByDate;
          }
          break;

        case LoadingSoonByDate:
          // Now load all the tasks that are soon.
          console.log(`LoadingSoonByDate: ${token ? 'with token' : 'null'}`);

          [i, n] = await doTheFetch(fetchByDate, token, { dueDate: soon }, items);
          items = i;
          nextToken = n;

          // If no next token, move on to the next state.
          if (!nextToken) {
            loadingState.current = LoadingSoonByCounters;
          }
          break;

        case LoadingSoonByCounters:
          console.log(`LoadingSoonByCounters: ${token ? 'with token' : 'null'}`);

          // Now load all the tasks that are soon.
          [i, n] = await doTheFetch(fetchUsingCounters, token, counterIsSoon, items);
          items = i;
          nextToken = n;

          // If no next token, move on to the next state.
          if (!nextToken) {
            loadingState.current = LoadingNullDates;
          }
          break;

        case LoadingNullDates:
          console.log(`LoadingNullDates: ${token ? 'with token' : 'null'}`);

          // Now load all the dates that are null.
          // TODO: Use fetchByDate with a filter for null dates.
          [i, n] = await doTheFetch(fetchByDate, token, null, items);
          items = i;
          nextToken = n;

          // If no next token, we're done.
          if (null == nextToken) {
            loadingState.current = LoadingAllCounters;
          }
          break;

        case LoadingAllCounters:
          console.log(`LoadingAllCounters: ${token ? 'with token' : 'null'}`);
          [i, n] = await doTheFetch(fetchUsingCounters, token, counterIsFuture, items);
          items = i;
          nextToken = n;

          // If no next token, done.
          if (!nextToken) {
            return { items, nextToken };
          }
          break;
          
        default:
          console.error("Unknown state.");
          throw new Error("Unknown state.");
      }

      // I've used the passed in token. Clear it, so don't use it in next state if looping.
      token = null;

      // If there's a nextToken, return -- we'll get called back for when the user scrolls to the bottom of the list.
      // If there isn't a nextToken, we should have moved to the next state in the state machine.
      if (nextToken) {
        return { items, nextToken };
      }
    }
  }, [fetchByDate, fetchUsingCounters, now, soon, sortType]);


  // This is the query for tasks assigned to someone. Call with the name
  // used in the query as the filter for this query.
  const tasksByItemIdAndAssigned =  useCallback((filterName) => {
    return `tasksByItemIdAndAssigned(
        itemId: $itemId
        assignedEmailPriority: $assignedEmailPriority
        sortDirection: $sortDirection
        filter: $${filterName}
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ${taskFields}
        }
        nextToken
      }
    `;
  }, []);


  // This is the fetch for tasks sorted by assigned.
  const fetchSortAssigned = useCallback(async (token) => {   
    // If there is no token, this is the initial query. Setup the nextToken state machine.
    const stateInitial = 0;
    const stateAssigned = 1;
    const stateUnassigned = 2;
    const stateDone = 3;

    if (!token) {
      loadingState.current = stateInitial;
      itemRef.current = null;
    }

    var variables = { 
      itemId: itemId,
      sortDirection: 'ASC',
      assignedFilter: { 
        and: [ 
          { assignedEmail: {ne: null, attributeExists: true} },
          { assignedEmail: {ne: '', attributeExists: true} }
        ]
      },
      unassignedFilter: {
        or: [ 
          { assignedEmail: {eq: null, attributeExists: true} },
          { assignedEmail: {eq: '', attributeExists: true} }
        ]
      }
    };


    let query = null;
    var itemsData = null;
    var items = [];
    var nextToken = null;

    switch (loadingState.current) {
      case stateInitial:
        query = `query TasksByItemIdAndAssigned(
          $itemId: ID!
          $assignedEmailPriority: ModelTaskPogoTaskByItemIdAndAssignedEmailCompositeKeyConditionInput
          $sortDirection: ModelSortDirection
          $assignedFilter: ModelTaskPogoTaskFilterInput
          $unassignedFilter: ModelTaskPogoTaskFilterInput
          $limit: Int
          $nextToken: String
        ) {
          ${getTaskPogoItem}
          assignedTasks: ${tasksByItemIdAndAssigned('assignedFilter')}
          unassignedTasks: ${tasksByItemIdAndAssigned('unassignedFilter')}
        }`;
        itemsData = await client.graphql({ query: query, variables: variables });
        
        // Go get any counters not already fetched in the item and remember the item.
        // TODO: This could be a bunch of roundtrips, do I need it? I don't anticipate
        // lots of counters, so I'll assume it's ok for now.
        await fetchAllItemCounters(itemsData.data.getTaskPogoItem);
        itemRef.current = itemsData.data.getTaskPogoItem;
        setItem(itemRef.current);

        // Get the items out of the assigned tasks.
        items = itemsData.data.assignedTasks.items;
        nextToken = itemsData.data.assignedTasks.nextToken;

        // If there is a nextToken, I will return the assigned tasks and the nextToken.
        // And we'll get called back with the nextToken later. Handle that in the next state.
        if (nextToken) {
          loadingState.current = stateAssigned;
          scratchpad.current = itemsData.data;
        } else {
          // Concatenate assigned and unassigned tasks.
          items = items.concat(itemsData.data.unassignedTasks.items);

          // If there is a nextToken in unassignedTasks, I will return it and we'll
          // get called back with it later. Handle that in the next state.
          if (itemsData.data.unassignedTasks.nextToken) {
            loadingState.current = stateUnassigned;
            scratchpad.current = itemsData.data.unassignedTasks;
            nextToken = itemsData.data.unassignedTasks.nextToken;
          } else {
            loadingState.current = stateDone;
          }
        }
        break;
      case stateAssigned:
        // In this state, we are passed the nextToken for the assigned tasks query.
        query = `query TasksByItemIdAndAssigned(
          $itemId: ID!
          $assignedEmailPriority: ModelTaskPogoTaskByItemIdAndAssignedEmailCompositeKeyConditionInput
          $sortDirection: ModelSortDirection
          $assignedFilter: ModelTaskPogoTaskFilterInput
          $limit: Int
          $nextToken: String
        ) {
          assignedTasks: ${tasksByItemIdAndAssigned('assignedFilter')}
        }`;
        itemsData = await client.graphql({ query: query, variables: variables });
        items = itemsData.data.assignedTasks.items;
        nextToken = itemsData.data.assignedTasks.nextToken;

        // If there is a nextToken, I will return the assigned tasks and the nextToken.
        // If not, I will concatenate the unassigned tasks and return them.
        if (!nextToken) {
          // Concatenate assigned and unassigned tasks.
          items = items.concat(scratchpad.current.items);
          
          // If there is a nextToken in unassignedTasks, I will return it and we'll
          // get called back with it later. Handle that in the next state.
          if (scratchpad.current.nextToken) {
            loadingState.current = stateUnassigned;
            nextToken = scratchpad.current.nextToken;
          }
        }
        break;

      case stateUnassigned:
        // In this state, we are passed the nextToken for the uassigned tasks query.
        // In this state, we are passed the nextToken for the assigned tasks query.
        query = `query TasksByItemIdAndAssigned(
          $itemId: ID!
          $assignedEmailPriority: ModelTaskPogoTaskByItemIdAndAssignedEmailCompositeKeyConditionInput
          $sortDirection: ModelSortDirection
          $unassignedFilter: ModelTaskPogoTaskFilterInput
          $limit: Int
          $nextToken: String
        ) {
          unassignedTasks: ${tasksByItemIdAndAssigned('unassignedFilter')}
        }`;
        itemsData = await client.graphql({ query: query, variables: variables });
        items = itemsData.data.unassignedTasks.items;
        nextToken = itemsData.data.unassignedTasks.nextToken;

        // If there is a nextToken, I will return the assigned tasks and the nextToken.
        // If not, I'm done.
        if (!nextToken) {
          loadingState.current = stateDone;
        }
        break;

      case stateDone:
        throw new Error("Shouldn't be called back in stateDone.");

      default:
        console.error(`Unknown state in fetchSortAssigned: ${loadingState.current}`);
        throw new Error("Unknown state.");
    }
    addBucketNameToTasks(items);
    items = filterForListType(listType, items);
    return { items, nextToken };
  }, [itemId, setItem, tasksByItemIdAndAssigned]);


  const fetchSortPriority = useCallback(async (token) => {
    let query = `query TasksByItemIdAndPriority(
      $itemId: ID!
      $priorityAssignedEmail: ModelTaskPogoTaskByItemIdAndPriorityCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $priorityFilter: ModelTaskPogoTaskFilterInput
      $noPriorityFilter: ModelTaskPogoTaskFilterInput
      $limit: Int
      $nextToken: String
    ) {
      ${token ? '' : getTaskPogoItem}

      priorityTasks: tasksByItemIdAndPriority(
        itemId: $itemId
        priorityAssignedEmail: $priorityAssignedEmail
        sortDirection: $sortDirection
        filter: $priorityFilter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ${taskFields}
        }
        nextToken
      }

      noPriorityTasks: tasksByItemIdAndPriority(
        itemId: $itemId
        priorityAssignedEmail: $priorityAssignedEmail
        sortDirection: $sortDirection
        filter: $noPriorityFilter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ${taskFields}
        }
        nextToken
      }
    }
    `;

    var variables = { 
      itemId: itemId,
      sortDirection: 'ASC',
      priorityFilter: { 
        and: [ 
          {
            priority: {ne: 0, attributeExists: true}
          }
        ]
      },
      noPriorityFilter: {
        or: [ 
          {
            priority: {eq: 0, attributeExists: true}
          },
          {
            priority: {attributeExists: false}
          }
        ]
      }
    };

    const itemsData = await client.graphql({ query: query, variables: variables });

    // Remember the item.
    if (!token && itemsData.data.getTaskPogoItem) {
      itemRef.current = itemsData.data.getTaskPogoItem;
      setItem(itemRef.current);
    }

    // First batch of overdue tasks based on date:
    var result = itemsData.data.priorityTasks;
    var items = result.items;

    // TODO: How to handle tokens?xx
    var nextToken = result.nextToken;
    if (!nextToken) {
      result = itemsData.data.noPriorityTasks;
      items = items.concat(result.items);
      nextToken = result.nextToken;
    }

    addBucketNameToTasks(items);

    // I post-filter to get rid of tasks based on the list-type. TODO: I wish the query could do this.
    items = filterForListType(listType, items);

    return { items, nextToken };
  }, [itemId, setItem, listType]);


  const fetchItems = useCallback(async (token) => {
    switch (sortType) {
      case SortOverdue:
        return fetchSortOverdue(token);
      case SortAssigned:
        return fetchSortAssigned(token);
      case SortPriority:
        return fetchSortPriority(token);
      default:
        throw new Error(`Unknown sort type ${sortType}`);
    }
  }, [fetchSortAssigned, fetchSortOverdue, fetchSortPriority, sortType]);

  return  fetchItems;
}


// Filter the items based on the list type. So, if the list type is overdue, only want the overdue tasks,
// filtering out the others.
function filterForListType(listType, items) {
  switch (listType) {
    case bucketNameAll:
      // Want everything, so no more filtering.
      break;
    case bucketNameOverdue:
      // Overdue tasks only. So only want dueDate <= now or counter <= taskCounter.
      items = items.filter((task) => { return task.bucketName == nameOverdue; });
      break;
    case bucketNameSoon:
      items = items.filter((task) => { return task.bucketName == nameSoon; });
      break;
    default:
      break;
  }
  return items;
}

